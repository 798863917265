import {
  createRouter,
  createMemoryHistory,
  createWebHistory,
  createWebHashHistory
} from 'vue-router'
import { defaultRoutes } from './routes'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

const createHistory = process.env.SERVER
  ? createMemoryHistory
  : process.env.VUE_ROUTER_MODE === 'history'
  ? createWebHistory
  : createWebHashHistory

const router = createRouter({
  scrollBehavior: () => ({
    left: 0,
    top: 0
  }),
  routes: defaultRoutes,

  // Leave this as is and make changes in quasar.conf.js instead!
  // quasar.conf.js -> build -> vueRouterMode
  // quasar.conf.js -> build -> publicPath
  history: createHistory(
    process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE
  )
})

// 替换现有router的routes, 修改matcher即新的routes生效
export function setRouter(routes) {
  router.matcher = createRouter({
    routes
  }).matcher
}

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter(options) {
  const newRouter = createRouter(options)
  router.matcher = newRouter.matcher // reset router
}

export default router
