/* eslint-disable no-use-before-define */
import Cookies from 'js-cookie'

//
// =============================Cookie:如二级域名需共享数据=============================
//

//
// Cookie信息
//
const CookieKey = 'goDuck_cookie'

export function getCookie() {
  return Cookies.get(CookieKey)
}

export function setCookie(token) {
  return Cookies.set(CookieKey, token)
}

export function removeCookie() {
  return Cookies.remove(CookieKey)
}

//
// =============================sessionStorage:浏览器关闭清除数据=============================
//

//
// Token信息
//
const TokenKey = 'goDuck_token'

export function getToken() {
  return sessionStorage.getItem(TokenKey)
}

export function setToken(token) {
  return sessionStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return sessionStorage.removeItem(TokenKey)
}

//
// 用户信息
//
const UserInfoKey = 'goDuck_userInfo'

export function getUserInfo() {
  const strInfo = sessionStorage.getItem(UserInfoKey)
  return strInfo ? JSON.parse(strInfo) : {}
}

export function setUserInfo(data) {
  const userInfo = formatUserInfo(data)
  const strInfo = JSON.stringify(userInfo)
  return sessionStorage.setItem(UserInfoKey, strInfo)
}

export function removeUserInfo() {
  return sessionStorage.removeItem(UserInfoKey)
}

// 格式化用户信息
const formatUserInfo = (data) => {
  return {
    name: data.name,
    email: data.email,
    deviceId: data.deviceId,
    platform: data.platform,
    type: data.type,
    uid: data.uid,
    vipTimeLeft: data.vipTimeLeft
  }
}

//
// =============================localStorage:永久保存手动删除=============================
//

//
// 配置信息
//
const ConfigInfoKey = 'goDuck_configInfo'

export function getConfigInfo() {
  const strInfo = localStorage.getItem(ConfigInfoKey)
  return strInfo ? JSON.parse(strInfo) : {}
}

export function setConfigInfo(data) {
  const configInfo = {
    language: data.language,
    timezone: data.timezone
  }
  const strInfo = JSON.stringify(configInfo)
  return localStorage.setItem(ConfigInfoKey, strInfo)
}

export function removeConfigInfo() {
  return localStorage.removeItem(ConfigInfoKey)
}

export function getLanguage() {
  const configInfo = getConfigInfo()
  return configInfo.language
}

export function getTimezone() {
  const configInfo = getConfigInfo()
  return configInfo.timezone
}
