export const defaultRoutes = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/home/IndexPage.vue') }
    ]
  },
  // {
  //   path: '/payment',
  //   component: () => import('layouts/MainLayout.vue'),
  //   children: [
  //     { path: '', component: () => import('pages/payment/IndexPage.vue') },
  //     {
  //       path: 'success',
  //       component: () => import('pages/payment/components/Success.vue')
  //     }
  //   ]
  // },
  {
    path: '/signup',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/signup/IndexPage.vue') }
    ]
  },
  {
    path: '/term&condition',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/termCondition/IndexPage.vue') }
    ]
  },
  {
    path: '/login',
    component: () => import('layouts/MainLayout.vue'),
    children: [{ path: '', component: () => import('pages/IndexPage.vue') }]
  },
  {
    path: '/subscribe',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/subscribe/IndexPage.vue') }
    ]
  },
  // {
  //   path: '/help',
  //   component: () => import('layouts/MainLayout.vue'),
  //   children: [
  //     { path: '', component: () => import('pages/help/IndexPage.vue') }
  //   ]
  // },
  {
    path: '/result',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/payment/RedirectPage.vue') }
    ]
  },
  {
    path: '/download',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/download/IndexPage.vue') },
      {
        path: 'info',
        component: () => import('pages/download/InfoPage.vue')
      },
      {
        path: 'info/new',
        component: () => import('pages/download/NewAppAccPage.vue')
      }
    ]
  },
  {
    path: '/ndweDfsWioOIJSNusind',
    component: () => import('layouts/EmptyLayout.vue'),
    children: [
      { path: '', component: () => import('pages/chat/IndexPage.vue') }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/home/IndexPage.vue') }
    ]
  }
]

export const constantRoutes = []
export const notFoundRoutes = []
