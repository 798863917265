import { LoadingBar } from 'quasar'
import { useUserStore } from 'stores/user'
import router from 'src/router/index'
// import { getPageTitle } from 'src/utils/getPageTitle'

router.beforeEach(async (to, from, next) => {
  // Login Page
  const loginPath = '/login'
  const signupPath = '/signup'

  const userStore = useUserStore()

  // console.log(from.path, to.path, userStore.token)

  // determine whether the account has logged in
  if (userStore.token) {
    if (!navigator.onLine) {
      $q.notify({
        type: 'negative',
        message: 'Please check your connection',
        position: 'top'
      })
      LoadingBar.stop()
    } else if (to.path === loginPath || to.path === signupPath) {
      next({ path: '/' })
      LoadingBar.stop()
    } else {
      try {
        // await userStore.getInfo()
        // const userInfo = userStore.userInfo // 用户信息
        if (userStore.routes.length === 0) {
          // await userStore.getRoles()
          // await userStore.setRoutes()
          // router.addRoute(userStore.routes)
          next()
        }
        next()
      } catch (err) {
        // Remove token and  to login page
        userStore.logout()
        next(`/?redirect=${to.path}`)
        LoadingBar.stop()
      }
      next()
    }
  } else {
    // const userStore = useUserStore()
    /* has no token */
    switch (to.path) {
      case '/':
        next()
      case '/signup':
        next()
      case '/term&condition':
        next()
      // case '/help':
      //   next()
      case '/login':
        next()
      case '/download':
        next()
      case '/ndweDfsWioOIJSNusind':
        next()
      case '/subscribe':
        // next({
        //   path: '/login',
        //   query: { isSubscribe: 'true', lang: userStore.lang }
        // })
        next()
      case '/download/info':
        next()
      case '/download/info/new':
        next()
      default:
        next(`/?redirect=${to.path}`)
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  LoadingBar.stop()
  // set page title
})
