<template>
  <router-view />
</template>

<script>
import { defineComponent } from 'vue'

import 'src/router/permission'

// document.body.style.zoom = '90%'
export default defineComponent({
  name: 'App'
})
</script>
