export default {
  homePage: {
    first: {
      unlimited: '无限流量，安全连接',
      highSpeed: '高速无痕浏览，GoDuck 网络加速器技术',
      ensure: '*为您安全上网保驾护航',
      hint: '*GoDuck 提供基于tsl技术的四层加密信息服务，深度保护您的数据安全'
    },
    second: {
      topTitle: '无论身在何处，GoDuck 为您提供高速，稳定的网络加速器 服务',
      topDesc: '在全球范围内提供超过300个智能站点，覆盖90+国家',
      bottomTitle: '无论何种设备，GoDuck为您提供安全，便捷的网络加速器服务',
      bottomDesc:
        'GoDuck 支持市面上的绝大多数主流设备，保障您的数据隐私. Windows, Mac, iOS, Android均可一键连接，无障碍使用'
    },
    third: {
      title: '下载GoDuck',
      win: 'Windows',
      mac: 'MacOs',
      adr: 'Android',
      ios: 'iOS',
      notAvailable: '暫不支持'
    },
    fourth: {
      title: '联系我们',
      descOne: '',
      descTwo: '',
      descThree: 'info@goduck.io',
      name: '姓名',
      email: '邮箱',
      subject: '主题',
      message: '请在这儿留言。。。',
      submit: '提交',
      nameFault: '请输入姓名',
      emailFault: '请输入邮箱',
      emailFormatFault: '邮箱格式错误',
      subjectFault: '请输入主题',
      messageFault: '留言不能超过500字',
      success: '留言成功，我们将尽快回复您！',
      fail: '留言失败，请稍后再试！'
    }
  },
  download: {
    win: {
      title: 'Windows 1.3.2',
      version: '支持Win7 / Win8 / Win10 / Win11',
      release: '发布日期： 2023.04.10'
    },
    mac: {
      title: 'macOS 1.3.5',
      version: '支持 macOS 10.12 以上',
      release: '发布日期： 2023.04.10'
    },
    adr: {
      title: 'Android 1.3.3',
      version: '支持 Android 10.0 以上',
      release: '发布日期： 2023.11.01',
      chinaBtn: '大陆下载',
      googleBtn: 'Google Play'
    },
    ios: {
      title: 'iOS 1.3.3',
      version: '支持 iOS 11.0 以上',
      release: '发布日期： 2023.11.01',
      chinaBtn: '大陆下载',
      appBtn: 'App Store'
    }
  },
  subscribe: {
    title: '现在购买，立享优惠',
    selection: '请选择支付款道：',
    creditCard: '信用卡',
    unionPay: '银联',
    wechatPay: '微信支付',
    crypto: '加密货币',
    package: {
      title: 'USD ',
      title2: '/天, 仅限抢购一次',
      perMonth: '每月',
      day: '天'
    },
    btnText: ' 下一步'
  },
  login: {
    username: '用户名',
    userEmpty: '请输入用户名',
    password: '密码',
    passEmpty: '请输入密码',
    signup: '注册',
    login: '登录'
  },
  signup: {
    username: '用户名',
    userHint: '* 6-16个字符，可使用英文字母和数字',
    userEmpty: '* 用户名不能为空',
    userFault: '* 用户名必须为6-16个字符，只能输入英文字母和数字',
    password: '密码',
    passHint: '* 6-16个字符，可使用英文字母、数字和符号',
    passEmpty: '* 密码不能为空',
    passFault: '* 密码必须为6-16个字符，只能输入英文字母、数字和符号',
    passwordThreeDotFault:
      '符号（支持全角+半角）：!" # $ % & \' ( ) * + , - . / : ; < = > ? @ [ \\ ] ^ _` { | } ~（32个字符）'
  },
  payment: {
    backBtn: '返回主页',
    paymentSuccess: '支付成功!',
    paymentFailed: '支付失败',
    paymentEmpty: '订单检查失败',
    createTime: '创建时间',
    orderId: '订单号码',
    packageName: '配套名称',
    name: '名字',
    amount: '金额',
    card: '卡号'
  },
  package: {
    title: '折合 SGD ',
    title2: '/天'
  },
  packageRedirect: {
    1: '一次性付款',
    2: '基本',
    3: '标准',
    4: '高级'
  },
  logoutDialog: {
    title: '确定要退出登录吗？',
    content: '退出后将不能查看您的账户信息，请谨慎操作'
  },
  footer: {
    aboutUs: '关于我们',
    language: '语言',
    privacy: '隐私协议',
    terms: '服务条款',
    copyright: '© 2023 by Matrix Networking PTE.LTD'
  },
  header: {
    login: '请在这登录',
    login2: '登录',
    logout: '退出登录',
    home: '首页',
    download: '下载',
    contact: '联系我们',
    help: '帮助中心',
    subscribe: '定价',
    vip: 'VIP',
    left: '剩余',
    days: '天',
    uid: 'UID: '
  },
  global: {
    download: '下载GoDuck',
    subscribe: '订阅GoDuck服务',
    read: '我已阅读',
    agreement: '服务条款',
    and: '和',
    privacy: '隐私协议',
    cancel: '取消',
    confirm: '同意',
    back: '返回',
    days: '天'
  },
  alert: {
    logout: '登出成功',
    login: '登录成功',
    register: '注册成功',
    status502: '网络异常，请联络管理员',
    loginFault: '用户名或密码错误',
    registerFault: '用户名已存在',
    wrong: '请稍后再试'
  },
  errorCode: {
    1000: '内部错误',
    1001: '检查登录失败',
    1002: '参数错误',
    1003: '用户名已存在',
    1004: '用户名或密码错误',
    1005: '密码错误',
    1006: '验证码错误',
    1007: 'Code不存在',
    1008: 'Code用户无效',
    1009: '当前设备过多',
    1010: '用户被禁用',
    1011: '旧密码错误',
    1012: '邮箱已存在',
    1016: '您已购买过此套餐'
  }
}
